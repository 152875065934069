import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const SupportModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Support" isOpen={isOpen} handleClose={handleClose}>
      <div className="columns-2">
        <p className="text-sm text-gray-500 dark:text-gray-300">
          As jy van 🥕Wortel hou en dankie wil sê, kan jy vir my 'n{' '}
          <a
            href="https://ko-fi.com/francoisbotha"
            className="font-bold underline"
            target="_blank"
            rel="noreferrer"
          >
            virtuele koffie koop
          </a>
          .
        </p>
        <p className="text-sm text-gray-500 dark:text-gray-300">
          If you dig the 🥕Wortel and want to thank me, you can{' '}
          <a
            href="https://ko-fi.com/francoisbotha"
            className="font-bold underline"
            target="_blank"
            rel="noreferrer"
          >
            buy me a virtual coffee
          </a>
          .{' '}
        </p>
      </div>
    </BaseModal>
  )
}
