import { SNOWFALL_DAY } from '../constants/settings'
import { getGameDate } from './words'

export const isSnowFallEnabled = () => {
  const gameDate = getGameDate()
  const snowFallDate = new Date(SNOWFALL_DAY)
  return (
    gameDate.getMonth() === snowFallDate.getMonth() &&
    gameDate.getDate() === snowFallDate.getDate()
  )
}
