import { FunctionComponent } from 'react'

type IconSVGProps = React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> &
  React.RefAttributes<SVGSVGElement>

type IconProps = IconSVGProps & {
  title?: string
  titleId?: string
  className?: string
}

type Icon = React.FC<IconProps>

type Props = {
  Icon?: Icon
  title: string
  onClick?: () => void
}

export const SidebarItem: FunctionComponent<Props> = ({
  Icon,
  title,
  onClick,
}) => {
  const clickHandler = onClick == null ? () => {} : onClick
  return (
    <li>
      <div
        className="group flex cursor-pointer items-center rounded-lg p-2 text-gray-900 dark:text-white"
        onClick={clickHandler}
      >
        {Icon && (
          <Icon className="h-6 w-6 cursor-pointer stroke-gray-500 hover:stroke-black dark:stroke-slate-300 dark:hover:stroke-white xxs:h-5 xxs:w-5" />
        )}
        {Icon && ' '}
        <span className="ms-3 cursor-pointer text-gray-500 dark:text-slate-300">
          {title}
        </span>
      </div>
    </li>
  )
}

//export default SidebarItem
