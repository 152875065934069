export const WORDS = [
  'kruis',
  'orige',
  'gewig',
  'polis',
  'album',
  'eksak',
  'ophou',
  'herfs',
  'dreig',
  'klank',
  'bruin',
  'eties',
  'brein',
  'vryer',
  'wraak',
  'aksie',
  'skaam',
  'boaan',
  'takel',
  'vyand',
  'klink',
  'leier',
  'seker',
  'graaf',
  'sterk',
  'geheg',
  'opsig',
  'lysie',
  'ruspe',
  'agent',
  'skets',
  'danig',
  'yster',
  'wysig',
  'fokus',
  'swaer',
  'vloek',
  'broei',
  'goeie',
  'insek',
  'geval',
  'skoot',
  'behou',
  'nooit',
  'keuse',
  'begin',
  'slaan',
  'oplet',
  'sprei',
  'moord',
  'slaag',
  'ingee',
  'kapot',
  'beoog',
  'beter',
  'skoen',
  'sesde',
  'benut',
  'reeks',
  'aflei',
  'yslik',
  'kafee',
  'rente',
  'gewel',
  'asook',
  'gesag',
  'sport',
  'blaai',
  'skadu',
  'aspek',
  'nadat',
  'smeek',
  'rebel',
  // 'walle',
  'sober',
  // 'belet',
  'kreef',
  // 'fikse',
  'skuil',
  // Old list until here - 2022-04-14

  'lelik',
  'amber',
  'skerm',
  'leuen',
  'syfer',
  'snork',
  'grein',
  'sweer',
  'bodem',
  'apart',
  'rusie',
  'drink',
  'spens',
  'afgod',
  // 'maart',
  'engte',
  'sluit',
  'vries',
  'grens',
  'vonds',
  'inbel',
  'trein',
  // 'teler',
  'bemin',
  'volop',
  'kwela',
  'spook',
  // 'varke',
  'wapen',
  'suite',
  'staan',
  // 'ryker',
  // 'luste',
  // 'skole',
  'koper',
  'snags',
  'koers',
  'proef',
  // 'skone',
  // 'blote',
  'plein',
  'groei',
  'getal',
  'peper',
  'pleit',
  // 'lange',
  'skeef',
  'inent',
  'bevel',
  'breed',
  'netto',
  'preek',
  'steek',
  'kopie',
  'fluit',
  'bekaf',
  'vloed',
  'selfs',
  'plaat',
  'stuur',
  'boord',
  'teken',
  'digby',
  'gloei',
  // 'villa',
  'klaar',
  'smeul',
  'inryg',
  'kadet',
  'galop',
  'gepas',
  'dring',
  // 'neefs',
  'tjirp',
  'stoof',
  'sluip',
  // 'fikse',
  'gelyk',
  'sweis',
  'roete',
  'kraak',
  'fonds',
  'angel',
  'sodat',
  'plant',
  'ougat',
  'drein',
  // 'volle',
  // 'afgee',
  'tjank',
  'krimp',
  'skors',
  'bykos',
  'swaar',
  'harig',
  'hulle',
  'baard',
  'kosyn',
  'onsin',
  'tempo',
  'maagd',
  'plaas',
  'orrel',
  'diens',
  'modem',
  'klomp',
  'gewyd',
  'jodel',
  'optog',
  'berei',
  'staak',
  'sweet',
  'skild',
  'basis',
  'beeld',
  'graad',
  'vraag',
  'baron',
  'raket',
  'oefen',
  'plooi',
  'nasie',
  // 'kante',
  'slurp',
  'diode',
  'barok',
  // 'tonne',
  'klant',
  'dwerg',
  'lewer',
  'onnie',
  'groet',
  'power',
  'tereg',
  'skade',
  'lepel',
  'plato',
  'satyn',
  'berug',
  'bleek',
  'skaal',
  'byeen',
  'grote',
  'vroeg',
  'nodig',
  'komma',
  'geluk',
  'beman',
  'droog',
  'opsie',
  'staat',
  'kudde',
  'opwek',
  'bende',
  'titel',
  'prima',
  // 'wette',
  'broek',
  // 'foute',
  'invul',
  'tensy',
  'afsak',
  'flits',
  'steel',
  'bloes',
  'innig',
  'frats',
  'stoel',
  'linne',
  'amper',
  'kabel',
  'banjo',
  'delta',
  'blaas',
  'veter',
  'kroeg',
  // 'ysige',
  'peins',
  'bekyk',
  'smeer',
  'hernu',
  'sedan',
  'tafel',
  'virus',

  // 'vaste',
  // 'optel',
  'dosyn',
  'sleep',
  'klerk',
  'kruip',
  'bevat',
  'massa',
  'suier',
  'weste',
  'sowel',
  'troue',
  'taboe',
  'pluis',
  'fiets',
  'stoom',
  'konyn',
  'geveg',
  'aarde',
  'koker',
  'skelm',
  'kaart',
  'blaar',
  'drank',
  'balju',
  'arend',
  'party',
  'bruto',
  'skimp',
  'denim',
  'beurt',
  'gloed',
  'flirt',
  'dwars',
  'stank',
  'spuit',
  'godin',
  'feeks',
  'strop',
  'luier',
  'aftel',
  'buite',
  'ditto',
  'kuier',
  'traag',
  'almal',
  'oulik',
  'vlerk',
  'boarm',
  'slegs',
  'depot',
  'kamer',
  'ritme',
  'kanoe',
  'kleur',
  'gerug',
  'prins',
  'anode',
  'vaart',
  'mosie',
  'hitte',
  'gholf',
  'genie',
  'kwaad',
  'model',
  'gebak',
  'larwe',
  'beton',
  'bonus',
  'effek',
  'storm',
  'pruik',
  'gelei',
  'duvet',
  'bokwa',
  'somer',
  'koste',
  'spons',
  'skuif',
  'priem',
  'smelt',
  'genot',
  'griep',
  'orkes',
  'troos',
  'skenk',
  'heers',
  'brief',
  'troef',
  'straf',
  'vloer',
  'stamp',
  'stort',
  'vrind',
  'minus',
  'kramp',
  'jolyt',
  'stryk',
  'klets',
  'kraag',
  'offer',
  'bloei',
  'wyser',
  'rugby',
  'piets',
  'houer',
  'rafel',
  'bydra',
  'gerus',
  'rosyn',
  'swaai',
  'sonde',
  'inkom',
  'kraan',
  'kloof',
  'grawe',
  'fabel',
  'ghoen',
  'blind',
  'klein',
  'media',
  'orals',
  'dwaal',
  'super',
  'stonk',
  'wyfie',
  'moles',
  'flank',
  'besit',
  'regte',
  'watse',
  'kweek',
  'alles',
  'fleur',
  'links',
  'skrap',
  'blaps',
  'setel',
  'reeds',
  'skiet',
  'derde',
  'steun',
  'stomp',
  'asjas',
  'suide',
  'tapyt',
  'stoet',
  'rower',
  'knaag',
  'vermy',
  'ketel',
  'alarm',
  'hyser',
  'brood',
  'troep',
  'druif',
  'donga',
  'droom',
  'fliek',
  'veras',
  'kombi',
  'palet',
  'spoed',
  'kroon',
  'genre',
  'poort',
  'brons',
  'kasty',
  'samie',
  'snoet',
  'tower',
  'dooie',
  'kruit',
  'vlieg',
  'karwy',
  'bolip',
  'vanaf',
  'albei',
  'besem',
  'stink',
  'motor',
  'spoel',
  'dosis',
  'groen',
  'slaai',
  'dwang',
  'spoeg',
  'vloei',
  'tante',
  'inset',
  'skrum',
  'terug',
  'swerm',
  'psige',
  'minag',
  'staal',
  'atlas',
  'sjiek',
  'afdek',
  'aroma',
  'appel',
  'altyd',
  'salie',
  'toeka',
  'druip',
  'speld',
  'woema',
  'husse',
  'viool',
  'erger',
  'skrif',
  'tronk',
  'steen',
  'afrig',
  'strak',
  'gogga',
  'plons',
  'greep',
  'derby',
  'wulps',
  'bloot',
  'meter',
  'geset',
  'hewig',
  'edele',
  'reken',
  'enorm',
  'radys',
  'bleik',
  'dwing',
  'ander',
  'flous',
  'faset',
  'skema',
  'vrede',
  'grond',
  'wafel',
  'gesog',
  'regie',
  'duits',
  'debat',
  'sfeer',
  'groot',
  'hamer',
  'vyfde',
  'ratel',
  'enige',
  'foyer',
  'vleis',
  'onder',
  'afkyk',
  'grief',
  'buurt',
  'veral',
  'vloot',
  'kluis',
  'kneus',
  'gewag',
  'bries',
  'chaos',
  'humor',
  'asbes',
  //  'gesak',
  'bewus',
  'nader',
  'bloed',
  //  'huise',
  'hoort',
  'knaap',
  'loods',
  'front',
  'wawyd',
  'omdat',
  'robyn',
  'klits',
  'arres',
  'bedel',
  'slang',
  'pleeg',
  'bisar',
  'liter',
  'skaap',
  'enjin',
  'beurs',
  'dekor',
  'lente',
  'asiel',
  'kolos',
  'direk',
  'skrop',
  'kloek',
  'deins',
  'breek',
  'traan',
  'motel',
  'dieet',
  'sagte',
  'skerp',
  'talle',
  'otter',
  'skryf',
  'sowat',
  'blits',
  'fnuik',
  'snert',
  'sneeu',
  'trust',
  'opera',
  'leser',
  'einde',
  'javel',
  'toets',
  'bouer',
  'spaar',
  'broos',
  'dowwe',
  'flans',
  'liewe',
  'steil',
  'water',
  'drang',
  'bolyf',
  'regop',
  'psalm',
  'datum',
  'sebra',
  'sodra',
  'nogal',
  'skuit',

  'roman',
  'junie',
  'spits',
  'darem',
  'ronde',
  'abuis',
  'opkom',
  'bedra',
  'slaap',
  'blink',
  'wasem',
  'omvat',
  'impak',
  'weens',
  'karos',
  'draai',
  'stang',
  'resep',
  'visie',
  'salon',
  'hoede',
  'afsny',
  'gruis',
  'smoel',
  'kriek',
  'wydte',
  'bewys',
  'harde',
  'kners',
  'vrank',
  'holte',
  'steak',
  'flink',
  'deken',
  'aptyt',
  'jaart',
  'maand',
  'gedig',
  'swelg',
  'kniel',
  'liefs',
  'sterf',
  'aknee',
  'bytel',
  'knoop',
  'traer',
  'kajak',
  'graag',
  'bedek',
  'stoei',
  'enkel',
  'pasta',
  'glips',
  'forum',
  'torso',
  'etiek',
  'vygie',
  'draad',
  'leuse',
  'speel',
  'akker',
  'inval',
  'gesel',
  'swerf',
  'belas',
  'adder',
  'manel',
  'kwota',
  'wrang',
  'kwart',
  'loket',
  'adres',
  'luuks',
  'komyn',
  'opval',
  'mafia',
  'kanon',
  'laken',
  'aster',
  'disko',
  'lende',
  'treil',
  'kwaai',
  'aflos',
  'firma',
  'urine',
  'spoor',
  'bekom',
  'gerub',
  'lemur',
  'gebod',
  'wesel',
  'oplos',
  'dadel',
  'haker',
  'uiter',
  'opset',
  'belet',
  'soort',
  'beken',
  'praat',
  'pienk',
  'drama',
  'stuit',
  'emmer',
  'hulde',
  'plank',
  'poets',
  'sluwe',
  'valse',
  'weier',
  'briek',
  'doepa',
  'padda',
  'harem',
  'selde',
  'byhou',
  'groep',
  'trant',
  'swoel',
  'skurk',
  'oprig',
  'pizza',
  'gemak',
  'dwelm',
  'dronk',
  'mirre',
  'skewe',
  'hekel',
  'stoep',
  'groef',
  'skink',
  'krans',
  'strem',
  'swiep',
  'bring',
  'aldus',
  'oewer',
  'gedra',
  'prang',
  'oksel',
  'lywig',
  'tydig',
  'hallo',
  'prooi',
  'sloot',
  'onreg',
  'matig',
  'hemel',
  'swets',
  'vrees',
  'lemma',
  'jurie',
  'bruid',
  'lapel',
  'lopie',
  'soete',
  'elfde',
  'beset',
  'etter',
  'sulke',
  'speur',
  'beste',
  'motto',
  'opvee',
  'glans',
  'maker',
  'berig',
  'prent',
  'ghoef',
  'skool',
  'binne',
  'jeans',
  'saldo',
  'fraai',
  'polka',
  'halwe',
  'stert',
  'stoot',
  'april',
  'woord',
  'brand',
  'stoor',
  'hetsy',
  'draer',
  'aluin',
  'kapel',
  'radio',
  'kring',
  'stres',
  'besig',
  'klier',
  'swaap',
  'lelie',
  'gesig',
  'langs',
  'onmin',
  'pronk',
  'trots',
  'smaak',
  'knoei',
  'ingat',
  'spier',
  'julle',
  'omset',
  'wysie',
  'kreun',
  'tesis',
  'konka',
  'gilde',
  'linie',
  'anker',
  'igloe',
  'gewas',
  'stout',
  'uniek',
  'gespe',
  'swart',
  'boete',
  'spalk',
  'klont',
  'vader',
  'llama',
  'oplig',
  'swink',
  'kiosk',
  'yskas',
  'immer',
  'afvee',
  'prosa',
  'gebou',
  'tabak',
  'kruim',
  'soeke',
  'pasop',
  'ooste',
  'deels',
  'sypel',
  'hings',
  'skulp',
  'stryd',
  'broer',
  'strek',
  'swenk',
  'afset',
  'elite',
  'later',
  'gesin',
  'skrik',
  'engel',
  'gereg',
  'kroek',
  'woede',
  'watte',
  'sloep',
  'saans',
  'pruim',
  'besef',
  'agter',
  'fluks',
  'solis',
  'erken',
  'strik',
  'wroeg',
  'vlooi',
  'ploeg',
  'eiser',
  'snoei',
  'ewige',
  'smous',
  'insig',
  'kapok',
  'kakie',
  'onlus',
  'fagot',
  'gebit',
  'smart',
  'hotel',
  'riool',
  'blond',
  'haaks',
  'sweef',
  'kelim',
  'welig',
  'vlies',
  'frons',
  'slank',
  'lower',
  'afval',
  'bybel',
  'video',
  'graan',
  'kwets',
  'woeps',
  'loper',
  'swoeg',
  'losie',
  'verby',
  'breuk',
  'katel',
  'skuur',
  'skril',
  'tabel',
  'salig',
  'skuld',
  'kakao',
  'hegte',
  'skuim',
  'hoeka',

  // Added 2024-05-22
  'abses',
  'kraam',
  'skeer',
  'ivoor',
  'euwel',
  'forel',
  'kleed',
  'snuit',
  'revue',
  'pyler',
  'beker',
  'snoek',
  'skeet',
  'sloof',
  'hawer',
  'poema',
  'snede',
  'opium',
  'snoep',
  'sjaal',
  'makou',
  'koets',
  'mango',
  'egter',
  'beide',
  'vreug',
  'swang',
  'eland',
  'spelt',
  'fudge',
  'soldy',
  'agaat',
  'dagga',
  'baken',
  'balie',
  'amorf',
  'ratio',
  'kluts',
  'speen',
  'skerf',
  'slier',
  'oneer',
  'staaf',
  'reier',
  'pleks',
  'order',
  'bewer',
  'steeg',
  'orent',
  'skede',
  'druis',
  'sloeg',
  'dolos',
  'slaak',
  'sleng',
  'apeks',
  'sepia',
  'opper',
  'rodeo',
  'malva',
  'sekel',
  'gelid',
  'skeur',
  'vista',
  'kleim',
  'sonar',
  'salsa',
  'aorta',
  'moker',
  'oopte',
  'snerp',
  'wemel',
  'skans',
  'skoor',
  'pruil',
  'pipet',
  'skoei',
  'split',
  'byval',
  'staar',
  'sonop',
  'rabbi',
  'kokon',
  'tepel',
  'roker',
  'draak',
  'apnee',
  'laser',
  'kroes',
  'uraan',
  'skeel',
  'berou',
  'kliek',
  'orden',
  'sinus',
  'dreun',
  'tatoe',
  'kubus',
  'onnet',
  'kegel',
  'ikoon',
  'vesel',
  'bioom',
  'speek',
  'pouse',
  'akuut',
  'panga',
  'naand',
  'bloos',
  'pynig',
  'sauna',
  'mamba',
  'tromp',
  'traak',
  'kroos',
  'dreef',
  'jongs',
  'sepie',
  'fetus',
  'kwaak',
  'snuif',
  'tonus',
  'rotor',
  'noord',
  'bruis',
  'arena',
  'snaps',
  'kleef',
  'venyn',
  'smoor',
  'oktet',
  'fauna',
  'manie',
  'truuk',
  'skaak',
  'optel',
  'stook',
  'netso',
  'vraat',
  'kolon',
  'dikte',
  'opsom',
  'pokke',
  'vliet',
  'potas',
  'polio',
  'oppas',
  'ambag',
  'beier',
  'pront',
  'patat',
  'bravo',
  'klong',
  'panda',
  'ledig',
  'koala',
  'vysel',
  'kruin',
  'treur',
  'slaaf',
  'pupil',
  'skaad',
  'afbyt',
  'kreet',
  'gebed',
  'filet',
  'naald',
  'credo',
  'wreed',
  'kobra',
  'blaam',
  'effen',
  'gouda',
  'braak',
  'ponie',
  'patee',
  'turbo',
  'opdok',
  'riepa',
  'ramba',
  'skaar',
  'sweep',
  'garde',
  'opreg',
  'fiool',
  'sabel',
  'oudag',
  'plaag',
  'tango',
  'etaan',
  'strot',
  'plomp',
  'toorn',
  'teuel',
  'tiran',
  'tsaar',
  'tumor',
  'oorle',
  'skort',
  'baber',
  'ester',
  'sisal',
  'kluit',
  'ouder',
  'pasga',
  'spuls',
  'batik',
  'flora',
  'tarra',
  'swier',
  'defek',
  'stand',
  'kraal',
  'vangs',
  'spuug',
  'kwaal',
  'ompad',
  'osoon',
  'vlaag',
  'skoon',
  'trans',
  'vodde',
  'praal',
  'puree',
  'abdis',
  'miaau',
  'beige',
  'samba',
  'pappa',
  'adieu',
  'stede',
  'stief',
  'plaak',
  'nodus',
  'skend',
  'behep',
  'fjord',
  'kroep',
  'oukei',
  'kolom',
  'skare',
  'kamma',
  'vroom',
  'joune',
  'polvy',
  'alias',
  'stulp',
  'koors',
  'swael',
  'bylae',
  'kebab',
  'kloon',
  'semel',
  'koord',
  'voort',
  'pekel',
  'proes',
  'spies',
  'vlees',
  'spaan',
  'jubel',
  'serie',
  'opvou',
  'sekte',
  'splyt',
  'vreet',
  'tiara',
  'bynes',
  'sadis',
  'steur',
  'skeen',
  'sloer',
  'snoer',
  'viets',
  'bloem',
  'skaai',
  'kiaat',
  'skaaf',
  'oraal',
  'papie',
  'folio',
  'dwaas',
  'amaas',
  'siter',
  'kader',
  'bebos',
  'bolla',
  'objek',
  'snaar',
  'marge',
  'roset',
  'ovaal',
  'dogma',
  'synde',
  'beuel',
  'mamma',
  'sibbe',
  'koket',
  'enema',
  'kruik',
  'erika',
  'bison',
  'rasie',
  'stram',
  'sopas',
  'motet',
  'kurwe',
  'sloop',
  'swaan',
  'vywer',
  'stelt',
  'graat',
  'jolig',
  'gewis',
  'robot',
  'braai',
  'visum',
  'frase',
  'beaam',
  'aftog',
  'troon',
  'klots',
  'nouer',
  'magma',
  'benul',
  'midde',
  'totem',
  'kneep',
  'braaf',
  'gysel',
  'spyte',
  'skurf',
  'pesto',
  'krenk',
  'wreek',
  'atjar',
  'ieder',
  'omega',
  'atoom',
  'terts',
  'kraai',
  'skree',
  'gleuf',
  // Reviewed until here
]
