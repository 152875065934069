import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const CreditsModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Erkenning" isOpen={isOpen} handleClose={handleClose}>
      <p className="mt-6 text-sm text-gray-500 dark:text-gray-300">
        🥕 Wortel is 'n woordraaispel. Dis{' '}
        <a
          href="https://github.com/cwackerfuss/react-wordle"
          className="font-bold underline"
          target="_blank"
          rel="noreferrer"
        >
          oopbronsagteware
        </a>{' '}
        met geen waarborge nie.
      </p>
      <p className="mt-6 text-sm text-gray-500 dark:text-gray-300">
        Afrikaanse instellings deur{' '}
        <a
          href="https://github.com/igitur"
          className="font-bold underline"
          target="_blank"
          rel="noreferrer"
        >
          {' '}
          Francois Botha
        </a>
        .
      </p>
      <p className="mt-6 text-sm text-gray-500 dark:text-gray-300">
        Met groot dank en erkenning aan die{' '}
        <a
          href="https://www.wat.co.za"
          className="font-bold underline"
          target="_blank"
          rel="noreferrer"
        >
          Woordeboek van die Afrikaanse Taal
        </a>{' '}
        vir hul bydrae tot Wortel.
      </p>
      <div className="mt-6 rounded-xl bg-white p-5">
        <a
          href="https://www.wat.co.za"
          className="font-bold underline"
          target="_blank"
          rel="noreferrer"
        >
          <img src="wat_logo.svg" alt="WAT logo" />
        </a>
      </div>
      <div className="mt-6 text-left text-sm text-gray-500 dark:text-gray-300">
        Ook dankie aan:
        <ul className="ml-4">
          <li>
            <strong>Wayne</strong>, wat die naam 'Wortel' voorgestel het.
          </li>
          <li>
            <strong>Grant</strong>, dat ek so baie idees met jou kan toets.
          </li>
        </ul>
      </div>
    </BaseModal>
  )
}
