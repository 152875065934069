import { Bars3Icon } from '@heroicons/react/24/outline'

import { GAME_TITLE } from '../../constants/strings'

type Props = {
  gameIndex: number
  setIsSidebarOpen: (value: boolean) => void
}

export const Navbar = ({ gameIndex, setIsSidebarOpen }: Props) => {
  return (
    <div className="navbar">
      <div className="navbar-content px-5 xxs:px-1 short:h-auto">
        <div className="flex">
          <Bars3Icon
            className="h-6 w-6 cursor-pointer stroke-gray-500 hover:stroke-black dark:stroke-slate-300 dark:hover:stroke-white xxs:h-5 xxs:w-5"
            onClick={() => setIsSidebarOpen(true)}
          />
        </div>
        <p>
          <span className="game-title font-serif text-2xl font-semibold dark:text-white xxs:text-xl">
            {GAME_TITLE}
          </span>
          <span className="game-title text-sm dark:text-white xxs:text-xs">
            {' '}
            #{gameIndex + 1}
          </span>
        </p>

        <div className="right-icons w-6"></div>
      </div>
      <hr></hr>
    </div>
  )
}
