import { SpinnerRoundFilled } from 'spinners-react'

export const GameLoader = () => (
  <div className="flex h-screen justify-center">
    <div className="m-auto">
      <SpinnerRoundFilled size="150" color="#777777" />
      <p className="ml-2.5 text-center text-xl font-bold dark:text-white">
        Loading...
      </p>
    </div>
  </div>
)
