import './App.css'

import {
  AdjustmentsHorizontalIcon,
  CalendarIcon,
  ChartBarIcon,
  ClockIcon,
  HeartIcon,
  InformationCircleIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline'
import { format } from 'date-fns'
import { default as GraphemeSplitter } from 'grapheme-splitter'
import { useEffect, useMemo, useState } from 'react'
import CacheBuster from 'react-cache-buster'
import Div100vh from 'react-div-100vh'
import Snowfall from 'react-snowfall'

import { AlertContainer } from './components/alerts/AlertContainer'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
import { CreditsModal } from './components/modals/CreditsModal'
import { DatePickerModal } from './components/modals/DatePickerModal'
import { InfoModal } from './components/modals/InfoModal'
import { MigrateStatsModal } from './components/modals/MigrateStatsModal'
import { SettingsModal } from './components/modals/SettingsModal'
import { StatsModal } from './components/modals/StatsModal'
import { SupportModal } from './components/modals/SupportModal'
import { Navbar } from './components/navbar/Navbar'
import { GameLoader } from './components/other/GameLoader'
import { Sidebar } from './components/sidebar/Sidebar'
import { SidebarItem } from './components/sidebar/SidebarItem'
import {
  DATE_LOCALE,
  DAY_OF_WEEK_TO_SHOW_CREDITS,
  DISCOURAGE_INAPP_BROWSERS,
  ENABLE_ARCHIVED_GAMES,
  LONG_ALERT_TIME_MS,
  MAX_CHALLENGES,
  REVEAL_TIME_MS,
  WELCOME_CREDITS_MODAL_MS,
  WELCOME_INFO_MODAL_MS,
} from './constants/settings'
import {
  CORRECT_WORD_MESSAGE,
  DISCOURAGE_INAPP_BROWSER_TEXT,
  GAME_COPIED_MESSAGE,
  HARD_MODE_ALERT_MESSAGE,
  LAST_GUESS_MESSAGES,
  LOST_MESSAGES,
  NOT_ENOUGH_LETTERS_MESSAGE,
  SHARE_FAILURE_TEXT,
  WIN_MESSAGES,
  WORD_NOT_FOUND_MESSAGE,
} from './constants/strings'
import { useAlert } from './context/AlertContext'
import { isInAppBrowser } from './lib/browser'
import {
  getStoredIsHighContrastMode,
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
  setStoredIsHighContrastMode,
} from './lib/localStorage'
import { isSnowFallEnabled } from './lib/snowfall'
import { addStatsForCompletedGame, loadStats } from './lib/stats'
import {
  findFirstUnusedReveal,
  getGameDate,
  getIndex,
  getIsLatestGame,
  isWinningWord,
  isWordInWordList,
  setGameDate,
  solution,
  solutionGameDate,
  unicodeLength,
} from './lib/words'

function App() {
  const isLatestGame = useMemo(getIsLatestGame, [])
  const gameDate = useMemo(getGameDate, [])
  const gameIndex = useMemo(() => getIndex(getGameDate()), [])
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches

  const { showError: showErrorAlert, showSuccess: showSuccessAlert } =
    useAlert()
  const [currentGuess, setCurrentGuess] = useState('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [winMessage, setWinMessage] = useState('')
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isDatePickerModalOpen, setIsDatePickerModalOpen] = useState(false)
  const [isMigrateStatsModalOpen, setIsMigrateStatsModalOpen] = useState(false)
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const [isCreditsModalOpen, setIsCreditsModalOpen] = useState(false)
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false)
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false)
  const [currentRowClass, setCurrentRowClass] = useState('')
  const [isGameLost, setIsGameLost] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme')
      ? localStorage.getItem('theme') === 'dark'
      : prefersDarkMode
      ? true
      : false
  )
  const [isHighContrastMode, setIsHighContrastMode] = useState(
    getStoredIsHighContrastMode()
  )
  const getRandomWinMessage = (numberOfGuesses: number) => {
    // If the game was solved on the last guess, we display select the message from a different list.
    const candidateMessages =
      numberOfGuesses === MAX_CHALLENGES ? LAST_GUESS_MESSAGES : WIN_MESSAGES

    return candidateMessages[
      Math.floor(Math.random() * candidateMessages.length)
    ]
  }
  const getRandomLoseMessage = () =>
    LOST_MESSAGES[Math.floor(Math.random() * LOST_MESSAGES.length)]
  const [isRevealing, setIsRevealing] = useState(false)
  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage(isLatestGame)
    if (loaded?.solution !== solution) {
      return []
    }
    const gameWasWon = loaded.guesses.includes(solution)
    if (gameWasWon) {
      setIsGameWon(true)
      setWinMessage(getRandomWinMessage(loaded.guesses.length))
    }
    if (loaded.guesses.length === MAX_CHALLENGES && !gameWasWon) {
      setIsGameLost(true)
      setWinMessage(getRandomLoseMessage())
      showErrorAlert(CORRECT_WORD_MESSAGE(solution), {
        persist: true,
      })
    }
    return loaded.guesses
  })

  const [stats, setStats] = useState(() => loadStats())

  const [isHardMode, setIsHardMode] = useState(
    localStorage.getItem('gameMode')
      ? localStorage.getItem('gameMode') === 'hard'
      : false
  )

  const isDayToShowCredits = useMemo(() => {
    const d = new Date()
    return d.getDay() === DAY_OF_WEEK_TO_SHOW_CREDITS
  }, [])

  useEffect(() => {
    // if no game state on load,
    // show the user the how-to info modal
    if (!isGameWon && isDayToShowCredits) {
      setTimeout(() => {
        setIsCreditsModalOpen(true)
      }, WELCOME_CREDITS_MODAL_MS)
    }
  }, [isGameWon, isDayToShowCredits])

  useEffect(() => {
    // if no game state on load,
    // show the user the how-to info modal
    if (!loadGameStateFromLocalStorage(true)) {
      setTimeout(() => {
        setIsInfoModalOpen(true)
      }, WELCOME_INFO_MODAL_MS)
    }
  })

  useEffect(() => {
    DISCOURAGE_INAPP_BROWSERS &&
      isInAppBrowser() &&
      showErrorAlert(DISCOURAGE_INAPP_BROWSER_TEXT, {
        persist: false,
        durationMs: 7000,
      })
  }, [showErrorAlert])

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }

    if (isHighContrastMode) {
      document.documentElement.classList.add('high-contrast')
    } else {
      document.documentElement.classList.remove('high-contrast')
    }
  }, [isDarkMode, isHighContrastMode])

  const handleDarkMode = (isDark: boolean) => {
    setIsDarkMode(isDark)
    localStorage.setItem('theme', isDark ? 'dark' : 'light')
  }

  const handleHardMode = (isHard: boolean) => {
    if (guesses.length === 0 || localStorage.getItem('gameMode') === 'hard') {
      setIsHardMode(isHard)
      localStorage.setItem('gameMode', isHard ? 'hard' : 'normal')
    } else {
      showErrorAlert(HARD_MODE_ALERT_MESSAGE)
    }
  }

  const handleHighContrastMode = (isHighContrast: boolean) => {
    setIsHighContrastMode(isHighContrast)
    setStoredIsHighContrastMode(isHighContrast)
  }

  const clearCurrentRowClass = () => {
    setCurrentRowClass('')
  }

  useEffect(() => {
    saveGameStateToLocalStorage(getIsLatestGame(), { guesses, solution })
  }, [guesses])

  useEffect(() => {
    if (isGameWon) {
      const delayMs = REVEAL_TIME_MS * solution.length

      showSuccessAlert(winMessage, {
        delayMs,
        onClose: () => setIsStatsModalOpen(true),
      })
    }

    if (isGameLost) {
      setTimeout(
        () => {
          setIsStatsModalOpen(true)
        },
        (solution.length + 1) * REVEAL_TIME_MS
      )
    }
  }, [guesses, isGameWon, isGameLost, winMessage, showSuccessAlert])

  const onChar = (value: string) => {
    if (
      unicodeLength(`${currentGuess}${value}`) <= solution.length &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    setCurrentGuess(
      new GraphemeSplitter().splitGraphemes(currentGuess).slice(0, -1).join('')
    )
  }

  const onEnter = () => {
    if (isGameWon || isGameLost) {
      return
    }

    if (!(unicodeLength(currentGuess) === solution.length)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(NOT_ENOUGH_LETTERS_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    if (!isWordInWordList(currentGuess)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(WORD_NOT_FOUND_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    // enforce hard mode - all guesses must contain all previously revealed letters
    if (isHardMode) {
      const firstMissingReveal = findFirstUnusedReveal(currentGuess, guesses)
      if (firstMissingReveal) {
        setCurrentRowClass('jiggle')
        return showErrorAlert(firstMissingReveal, {
          onClose: clearCurrentRowClass,
        })
      }
    }

    setIsRevealing(true)
    // turn this back off after all
    // chars have been revealed
    setTimeout(() => {
      setIsRevealing(false)
    }, REVEAL_TIME_MS * solution.length)

    const winningWord = isWinningWord(currentGuess)

    if (
      unicodeLength(currentGuess) === solution.length &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setGuesses([...guesses, currentGuess])
      setCurrentGuess('')

      if (winningWord) {
        if (isLatestGame) {
          setStats(addStatsForCompletedGame(stats, guesses.length))
        }
        setWinMessage(getRandomWinMessage(guesses.length + 1))
        return setIsGameWon(true)
      }

      if (guesses.length === MAX_CHALLENGES - 1) {
        if (isLatestGame) {
          setStats(addStatsForCompletedGame(stats, guesses.length + 1))
        }
        setIsGameLost(true)
        setWinMessage(getRandomLoseMessage())
        showErrorAlert(CORRECT_WORD_MESSAGE(solution), {
          persist: true,
          delayMs: REVEAL_TIME_MS * solution.length + 1,
        })
      }
    }
  }

  const isProduction = process.env.NODE_ENV === 'production'
  const appVersion = process.env.REACT_APP_VERSION ?? 'undefined'

  return (
    <CacheBuster
      currentVersion={appVersion}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<GameLoader />} //If not pass, nothing appears at the time of new version check.
    >
      <Div100vh>
        {isSnowFallEnabled() && <Snowfall />}

        <Sidebar
          isOpen={isSidebarOpen}
          handleClose={() => setIsSidebarOpen(false)}
        >
          <SidebarItem
            Icon={ChartBarIcon}
            title="My statistiek"
            onClick={() => {
              setIsSidebarOpen(false)
              setIsStatsModalOpen(true)
            }}
          />

          {ENABLE_ARCHIVED_GAMES && (
            <SidebarItem
              Icon={CalendarIcon}
              title="Toeka s'n"
              onClick={() => {
                setIsSidebarOpen(false)
                setIsDatePickerModalOpen(true)
              }}
            />
          )}

          <SidebarItem
            Icon={UserGroupIcon}
            title="Erkenning"
            onClick={() => {
              setIsSidebarOpen(false)
              setIsCreditsModalOpen(true)
            }}
          />

          <SidebarItem
            Icon={HeartIcon}
            title="Ondersteun"
            onClick={() => {
              setIsSidebarOpen(false)
              setIsSupportModalOpen(true)
            }}
          />

          <SidebarItem
            Icon={InformationCircleIcon}
            title="Hoe speel mens?"
            onClick={() => {
              setIsSidebarOpen(false)
              setIsInfoModalOpen(true)
            }}
          />

          <SidebarItem
            Icon={AdjustmentsHorizontalIcon}
            title="Instellings"
            onClick={() => {
              setIsSidebarOpen(false)
              setIsSettingsModalOpen(true)
            }}
          />
        </Sidebar>

        <div className="flex h-full flex-col">
          <Navbar gameIndex={gameIndex} setIsSidebarOpen={setIsSidebarOpen} />

          {!isLatestGame && (
            <div className="flex items-center justify-center">
              <ClockIcon className="h-6 w-6 stroke-gray-600 dark:stroke-gray-300 short:h-5 short:w-5 xshort:h-4 xshort:w-4" />
              <p className="text-base text-gray-600 dark:text-gray-300 short:text-sm xshort:text-xs">
                {format(gameDate, 'd MMMM yyyy', { locale: DATE_LOCALE })}
              </p>
            </div>
          )}

          <div className="mx-auto flex w-full grow flex-col px-1 pb-8 pt-2 sm:px-6 md:max-w-7xl lg:px-8 short:pb-2 short:pt-2">
            <div className="flex grow flex-col justify-center pb-6 short:pb-2">
              <Grid
                solution={solution}
                guesses={guesses}
                currentGuess={currentGuess}
                isRevealing={isRevealing}
                currentRowClassName={currentRowClass}
              />
            </div>
            <Keyboard
              onChar={onChar}
              onDelete={onDelete}
              onEnter={onEnter}
              solution={solution}
              guesses={guesses}
              isRevealing={isRevealing}
            />
            <InfoModal
              isOpen={isInfoModalOpen}
              appVersion={appVersion}
              handleClose={() => setIsInfoModalOpen(false)}
            />
            <CreditsModal
              isOpen={isCreditsModalOpen}
              handleClose={() => setIsCreditsModalOpen(false)}
            />
            <StatsModal
              isOpen={isStatsModalOpen}
              handleClose={() => setIsStatsModalOpen(false)}
              solution={solution}
              guesses={guesses}
              gameStats={stats}
              isLatestGame={isLatestGame}
              isGameLost={isGameLost}
              isGameWon={isGameWon}
              handleShareToClipboard={() =>
                showSuccessAlert(GAME_COPIED_MESSAGE)
              }
              handleShareFailure={() =>
                showErrorAlert(SHARE_FAILURE_TEXT, {
                  durationMs: LONG_ALERT_TIME_MS,
                })
              }
              handleMigrateStatsButton={() => {
                setIsStatsModalOpen(false)
                setIsMigrateStatsModalOpen(true)
              }}
              isHardMode={isHardMode}
              isDarkMode={isDarkMode}
              isHighContrastMode={isHighContrastMode}
              numberOfGuessesMade={guesses.length}
              winMessage={winMessage}
            />
            <DatePickerModal
              isOpen={isDatePickerModalOpen}
              initialDate={solutionGameDate}
              handleSelectDate={(d) => {
                setIsDatePickerModalOpen(false)
                setGameDate(d)
              }}
              handleClose={() => setIsDatePickerModalOpen(false)}
            />
            <MigrateStatsModal
              isOpen={isMigrateStatsModalOpen}
              handleClose={() => setIsMigrateStatsModalOpen(false)}
            />
            <SettingsModal
              isOpen={isSettingsModalOpen}
              handleClose={() => setIsSettingsModalOpen(false)}
              isHardMode={isHardMode}
              handleHardMode={handleHardMode}
              isDarkMode={isDarkMode}
              handleDarkMode={handleDarkMode}
              isHighContrastMode={isHighContrastMode}
              handleHighContrastMode={handleHighContrastMode}
            />
            <SupportModal
              isOpen={isSupportModalOpen}
              handleClose={() => setIsSupportModalOpen(false)}
            />
            <AlertContainer />
          </div>
        </div>
      </Div100vh>
    </CacheBuster>
  )
}

export default App
