import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { addDays, format, startOfDay } from 'date-fns'
import { useMemo, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'

import { DATE_LOCALE } from '../../constants/settings'
import {
  DATEPICKER_CHOOSE_TEXT,
  DATEPICKER_TITLE,
} from '../../constants/strings'
import { getToday } from '../../lib/dateutils'
import {
  firstGameDate,
  getIndex,
  getLastGameDate,
  isValidGameDate,
  periodInDays,
} from '../../lib/words'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  initialDate?: Date
  handleSelectDate: (date: Date) => void
  handleClose: () => void
}

export const DatePickerModal = ({
  isOpen,
  initialDate,
  handleSelectDate,
  handleClose,
}: Props) => {
  const today = useMemo(() => getToday(), [])
  const lastGameDate = useMemo(() => getLastGameDate(today), [today])
  const [selectedDate, setSelectedDate] = useState(() => {
    if (initialDate == null || initialDate > lastGameDate) {
      return lastGameDate
    }
    return initialDate
  })

  const [selectedIndex, setSelectedIndex] = useState(() =>
    getIndex(selectedDate)
  )

  const headingDateFormat = 'MMMM yyyy'
  const buttonDateFormat = 'd MMM yyyy'
  const formatOptions = { locale: DATE_LOCALE }

  registerLocale('locale', DATE_LOCALE)

  const excludedDates: Date[] = []
  if (periodInDays > 1) {
    let date = firstGameDate
    for (date = firstGameDate; date <= today; date = addDays(date, 1)) {
      if (!isValidGameDate(date)) {
        excludedDates.push(date)
      }
    }
  }

  const handleOnChange = (date: Date) => {
    const gameDate = startOfDay(date)
    setSelectedDate(gameDate)
    const gameIndex = getIndex(gameDate)
    setSelectedIndex(gameIndex)
  }

  const handleDayClassName = (date: Date) => {
    return date.getTime() === today.getTime()
      ? 'border-2 border-indigo-600'
      : ''
  }

  return (
    <BaseModal
      title={DATEPICKER_TITLE}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <div className="mx-auto flex max-w-2xl items-center justify-center space-x-4 py-5 text-left sm:w-48">
        <DatePicker
          locale="locale"
          minDate={firstGameDate}
          maxDate={today}
          selected={selectedDate}
          excludeDates={excludedDates}
          onChange={handleOnChange}
          inline
          popperClassName="react-datepicker-left"
          dayClassName={(date) => handleDayClassName(date)}
          renderCustomHeader={({
            date,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div className="flex items-center justify-between px-2 py-2">
              <span className="text-lg text-gray-700 dark:text-gray-100">
                {format(date, headingDateFormat, formatOptions)}
              </span>

              <div className="space-x-2">
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                  type="button"
                  className={` ${
                    prevMonthButtonDisabled && 'cursor-not-allowed opacity-50'
                  } inline-flex rounded border border-gray-300 bg-white p-1 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-0 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-200 dark:focus:ring-blue-600`}
                >
                  <ChevronLeftIcon className="h-5 w-5 text-gray-600 dark:text-gray-300" />
                </button>

                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                  type="button"
                  className={` ${
                    nextMonthButtonDisabled && 'cursor-not-allowed opacity-50'
                  } inline-flex rounded border border-gray-300 bg-white p-1 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-0 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-200 dark:focus:ring-blue-600`}
                >
                  <ChevronRightIcon className="h-5 w-5 text-gray-600 dark:text-gray-300" />
                </button>
              </div>
            </div>
          )}
        />
      </div>
      <div className="flex justify-center">
        <button
          type="button"
          className="inline-flex w-2/3 items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-1/2"
          disabled={selectedDate.getTime() > today.getTime()}
          onClick={() => handleSelectDate(selectedDate)}
        >
          {DATEPICKER_CHOOSE_TEXT} #{selectedIndex + 1}
          <br />
          {format(selectedDate, buttonDateFormat, formatOptions)}
        </button>
      </div>
    </BaseModal>
  )
}
