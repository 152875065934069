import { XCircleIcon } from '@heroicons/react/24/outline'
import { PropsWithChildren } from 'react'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const Sidebar = ({
  isOpen,
  handleClose,
  children,
}: PropsWithChildren<Props>) => {
  const expandedClass = isOpen ? 'translate-x-0' : '-translate-x-full'
  const sidebarClasses = `fixed top-0 left-0 z-40 h-screen w-64 overflow-y-auto bg-white p-4 transition-transform dark:bg-slate-800 ${expandedClass}`

  return (
    <div>
      {' '}
      <div
        id="drawer-navigation"
        className={sidebarClasses}
        tabIndex={-1}
        aria-labelledby="drawer-navigation-label"
      >
        <span
          id="drawer-navigation-label"
          className="text-base font-semibold uppercase text-gray-500 dark:text-gray-400"
        >
          🥕
        </span>

        <XCircleIcon
          className="end-2.5 absolute top-2.5 inline-flex h-6 w-6 cursor-pointer bg-transparent text-gray-400 hover:text-black dark:hover:text-white"
          onClick={handleClose}
        />

        <div className="overflow-y-auto py-4">
          <ul className="space-y-2 font-medium">
            {children}

            <li>
              <a
                className="group flex cursor-pointer items-center rounded-lg p-2 text-gray-900 dark:text-white"
                href="https://www.wat.co.za/produk/bestelvorm/"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25"
                  />
                </svg>

                <span className="ms-3 cursor-pointer text-gray-500 dark:text-slate-300">
                  Aanlyn WAT
                </span>
              </a>
            </li>
            <li>
              <a
                className="group flex cursor-pointer items-center rounded-lg p-2 text-gray-900 dark:text-white"
                href="https://www.facebook.com/groups/speelwortel"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  className="h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <title>Facebook</title>
                  <path d="M9.101 23.691v-7.98H6.627v-3.667h2.474v-1.58c0-4.085 1.848-5.978 5.858-5.978.401 0 .955.042 1.468.103a8.68 8.68 0 0 1 1.141.195v3.325a8.623 8.623 0 0 0-.653-.036 26.805 26.805 0 0 0-.733-.009c-.707 0-1.259.096-1.675.309a1.686 1.686 0 0 0-.679.622c-.258.42-.374.995-.374 1.752v1.297h3.919l-.386 2.103-.287 1.564h-3.246v8.245C19.396 23.238 24 18.179 24 12.044c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.628 3.874 10.35 9.101 11.647Z" />
                </svg>
                <span className="ms-3 cursor-pointer text-gray-500 dark:text-slate-300">
                  Facebook
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
